import * as PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import { COLORS } from '../../assets/styles/variables';
import FontIcon from '../../atoms/FontIcon';
import Text from '../../atoms/Text';
import { phone } from '../../utils/media';

const QuoteSectionWrapper = styled.div`
  display: grid;
  grid-auto-flow: row;
  justify-items: center;
  align-items: center;
  padding: 0;
  max-width: 1000px;
  margin: auto;
  margin-bottom: 40px;
  text-align: center;
`;

const QuoteTextWrapper = styled.div`
  margin: 30px 0;
  padding: 40px 40px;
  width: 75%;
  font-style: italic;
  position: relative;
  display: block;

  ${phone(css`
    width: 75%;
    padding: 0 10px;
  `)}
`;

const QuoteIconTopWrapper = styled.div`
  position: absolute;
  opacity: 0.1;
  top: 5px;
  left: -50px;
  font-size: 120px;

  ${phone(
    css`
      top: -80px;
      left: -15px;
    `,
  )};
`;

const QuoteIconBottomWrapper = styled.div`
  position: absolute;
  bottom: -0.1em;
  opacity: 0.1;
  left: 80%;
  transform: rotate(180deg);
  font-size: 120px;

  ${phone(
    css`
      font-size: 60px;
      bottom: -0.5em;
      left: 75%;
      transform: rotate(180deg);
    `,
  )};
`;

const MobileText = styled(Text)`
  ${phone(
    css`
      font-size: 16px;
      padding: 0 15px;
    `,
  )};
`;

const MobileFont = styled(FontIcon)`
  ${phone(
    css`
      font-size: 60px;
    `,
  )};
`;
const QuoteSection = ({ topDescription, quoteText, bottomDescription }) => {
  return (
    <QuoteSectionWrapper>
      <MobileText size={'20px'} lineHeight={2}>
        {topDescription}
      </MobileText>
      <QuoteTextWrapper>
        <QuoteIconTopWrapper position={'left'}>
          <MobileFont className={'quote-icon left-quote'} icon={'quote'} color={COLORS.brand} size={'120px'} />
        </QuoteIconTopWrapper>
        <Text color={COLORS.shadow} size={'22px'} lineHeight={2}>
          {quoteText}
        </Text>
        <QuoteIconBottomWrapper position={'right'}>
          <MobileFont className={'quote-icon right-quote'} icon={'quote'} color={COLORS.brand} size={'120px'} />
        </QuoteIconBottomWrapper>
      </QuoteTextWrapper>
      <MobileText size={'20px'} lineHeight={2}>
        {bottomDescription}
      </MobileText>
    </QuoteSectionWrapper>
  );
};

QuoteSection.propTypes = {
  topDescription: PropTypes.string.isRequired,
  quoteText: PropTypes.string.isRequired,
  bottomDescription: PropTypes.string.isRequired,
};

export default withTranslation()(QuoteSection);
